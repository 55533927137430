"use client";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { getAuth } from "firebase/auth";
import { AuthProvider, useFirebaseApp } from "reactfire";
export default function FirebaseProvider({ children }: { children: JSX.Element }) {
  const firebaseAuth = getAuth(useFirebaseApp());

  return (
    <AuthProvider sdk={firebaseAuth}>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID as string}>
        {children}
      </GoogleOAuthProvider>
    </AuthProvider>
  );
}
