import(/* webpackMode: "eager" */ "/vercel/path0/apps/box-office/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/emotion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/firebase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/messages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/box-office/src/lib/providers/swr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/apps/box-office/src/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/carousel/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/charts/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Center"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Center/Center.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Loader"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Loader/Loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/dates/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/nprogress/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/spotlight/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/tiptap/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
